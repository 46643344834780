import PropTypes from 'prop-types'
import './Select.scss'

const Select = (props) => {

    const renderOptions = () => {
        if (!props.options || props.options.length === 0) {
            return <option value="-1">No options available</option>
        }

        return props.options.map((option, index) => {
            return <option 
                key={index} 
                className="Option"  
                value={option}>{option}</option>
        })
    }

    return <div className="SelectInput">
        <label className="Label" htmlFor="options">
            {props.label}
        </label>
        <select 
            name="options" 
            defaultValue={props.value}
            className="Select"
            id="Select" onChange={(e) => props.onChange(e.target.value)}>
            {renderOptions()}
        </select>
    </div>
}

Select.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    options: PropTypes.array.isRequired
}

export default Select