const imageConfigs = {
    0: {
        image: "Turkey Mosque Sunset Skyline",
        credit: {
            url: "https://unsplash.com/@aliarifsoydas",
            name: "Ali Arif Soydaş",
            site: "Unsplash",
        },
        imageTopOffset: 10,
        whiteText: true,
    },
    1: {
        description: "Turkey Blue Mosque Skyline",
        credit: {
            url: "https://unsplash.com/@adliwahid",
            name: "Adli Wahid",
            site: "Unsplash",
        },
        imageTopOffset: 10,
        whiteText: true,
    },
    2: {
  
        description: "Masjid Pogung Dalangan",
        credit: {
            url: "https://unsplash.com/@masjidmpd",
            name: "Masjid Pogung Dalangan",
            site: "Unsplash",
        },
        imageTopOffset: 10,
        whiteText: true,
    },
    3: {
  
        description: "Süleymaniye Mosque, Süleymaniye, Fatih/İstanbul, Türkiye",
        credit: {
            url: "https://unsplash.com/@damlaozkan",
            name: "Damla Ozkan",
            site: "Unsplash",
        },
        imageTopOffset: 10,
        whiteText: true,
    },
    4: {
  
        description: "Deira - Dubai - United Arab Emirates",
        credit: {
            url: "https://unsplash.com/@levimeirclancy",
            name: "Levi Meir Clancy",
            site: "Unsplash",
        },
        imageTopOffset: 10,
        whiteText: true,
    },
    5: {
  
        description: "Dome of the Rock, Jerusalem, Palestine",
        credit: {
            url: "https://unsplash.com/@staceyfranc0",
            name: "Stacey Franco",
            site: "Unsplash",
        },
        imageTopOffset: 10,
    },
}

export default imageConfigs