const Copyright = () => {
    return (
        <div style={{
            position:"relative",
            marginTop:"43vh",
            width:"100%",
            backgroundColor:"#333333",
        }}>
            <div style={{
                position: "absolute",
                width: "100%",
                color: "#fff",
                lineHeight: "40px",
                fontSize: "0.7em",
                textAlign: "center",
            }}>
                &#169; 2021 Fhazer Systems.
            </div>
        </div>
    )
}

export default Copyright