import { useState } from 'react'
import SearchButton from '../../atoms/SearchButton/SearchButton'
import SearchInput from '../../atoms/SearchInput/SearchInput'
import './SearchBar.scss'
import SEARCH_ENDPOINTS from '../../../data/SearchEngine/SearchEngines'
import { useSelector } from 'react-redux'
import SearchRecommendations from '../../molecules/SearchRecommendations/SearchRecommendations'

const DEFAULT_ENGINE = "Google"

const SearchBar = (props) => {
    const [searchTerms, setTerms] = useState('')
    const searchEngine = useSelector(state => state.user.searchEngine)


    const search = () => {
        if (searchTerms && searchTerms.length > 0) {
            let searchEndpoint = SEARCH_ENDPOINTS[searchEngine] || SEARCH_ENDPOINTS[DEFAULT_ENGINE]
            searchEndpoint = searchEndpoint + searchTerms.split(' ').join('+')
            window.location.href = searchEndpoint
        }
    }

    return <div className="Search-Bar">
        <div className="Input-Button">
            <SearchInput searchTerms={searchTerms} setTerms={setTerms} search={search}/>
            <SearchButton search={search}/>
        </div>
    </div>
}

export default SearchBar