import './NewTab.scss'
import LargeClock from '../../atoms/LargeClock/LargeClock'
import Greeting from '../../molecules/Greeting/Greeting'
import BackgroundImage from '../../atoms/BackgroundImage/BackgroundImage';
import Quote from '../../atoms/Quote/Quote';
import Settings from '../../organisms/Settings/Settings';
import { useState } from 'react';
import SearchBar from '../../organisms/SearchBar/SearchBar';
import { PrayerTimes } from '../../organisms/PrayerTimes/PrayerTimes';
import { useEffect } from 'react';
import { logAnalytics } from '../../../data/Analytics/analytics';
import {Helmet} from "react-helmet"


const NewTab = (props) => {
    const [showSettings, toggleSettings] = useState(false)
    const [showPrayerTimes, togglePrayerTimes] = useState(false)

    useEffect(() => {
      logAnalytics('new_tab_page_view')
    }, []) 

    useEffect(() => {
        if (showSettings && showPrayerTimes) {
        togglePrayerTimes(false)
        }
        logAnalytics('toggle_settings')
    }, [showSettings])

    useEffect(() => {
        if (showSettings && showPrayerTimes) {
        toggleSettings(false)
        }
        logAnalytics('toggle_prayer_times')
    }, [showPrayerTimes])
  
    return <div className="New-Tab">
        <Helmet>
          <title>Ajr | From Tabs </title>
        </Helmet>
        <div className="App">
            <BackgroundImage className="Background" backgroundImageIndex={0}/>
        </div>
        <div className="App Settings">
            <Settings showSettings={showSettings} toggleSettings={toggleSettings}/>
        </div>
        <div className="App Prayer-Times">
            <PrayerTimes 
              showPrayerTimes={showPrayerTimes} 
              togglePrayerTimes={togglePrayerTimes}
              toggleSettings={toggleSettings}/>
        </div>
        <div className="App Clock">
            <LargeClock className="Foreground" showSeconds={false}/>
        </div>
        <div className="App Greeting">
          <Greeting className="Foreground" displayName="Muhammed"/>
        </div>
        <div className="App Search">
          <SearchBar searchEngine="google"/>
        </div>
        <div className="App Quote">
          <Quote className="Foreground"/>
        </div>
    </div>
}

export default NewTab