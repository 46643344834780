import axios from 'axios'

const getAutocompleteEndpoint = (searchTerm) => {
    let env = process.env.REACT_APP_ENV
    if (env === "dev") {
        return `https://4e9evdtpg9.execute-api.eu-west-2.amazonaws.com/dev?search=${searchTerm}`
    }
    return `https://4e9evdtpg9.execute-api.eu-west-2.amazonaws.com/prod?search=${searchTerm}`
}

const getApiKey = () => {
    if (process.env.REACT_APP_ENV === "dev") {
        return "UJUiDNDO4c10VuTcdCG4U1tAGCIsa2hv5zK60yqW"
    } else {
        return "iR9zdXSWAm50YjPJInA8yLws7xnhjLh5nC1RhcS0"
    }
}

const getAutocompleteRecs = (searchTerm) => {
    const config = {
        headers: {
            'x-api-key': getApiKey()
        }
    }
    const endpoint = getAutocompleteEndpoint(searchTerm)
    return axios.get(endpoint, config)
        .then(response => {
            return response.data
        })
        .catch(() => [])
}

export {
    getAutocompleteRecs
}