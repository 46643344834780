import './ToggleButton.scss'
import PropTypes from 'prop-types'

const ToggleButton = (props) => {
    return <div className={"Cog " + props.className} 
            onClick={() => props.toggle(!props.show)}
            style={props.style}>
        {props.content}
    </div>
}

ToggleButton.propTypes = {
    content: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    style: PropTypes.object,
    className: PropTypes.string
}

export default ToggleButton