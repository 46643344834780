import './Greeting.scss'
import PropTypes from 'prop-types'
import greetings from '../../../data/Greeting/Greetings'
import { useEffect, useState } from 'react'
import DisplayName from '../../atoms/DisplayName/DisplayName'
import { useSelector } from 'react-redux'

const Greeting = (props) => {
    const [greetingIndex, setGreetingIndex] = useState(0)
    const [greeting, setGreeting] = useState(greetings[greetingIndex])
    const [currentClass, setCurrentClass] = useState("Fade-In")
    const [hide, shouldHide] = useState(false)

    const username = useSelector(state => state.user.name)

    useEffect(() => {
        let greetingSwitchTimer = setInterval(() => {
            setCurrentClass("Fade-Out")
            setTimeout(() => shouldHide(true), 900)
            setTimeout(() => {
                setGreetingIndex((greetingIndex + 1) % greetings.length)
                setCurrentClass("Fade-In")
                shouldHide(false)
            }, 1500)
        }, 1000*1)   

        return () => {
            clearInterval(greetingSwitchTimer)
        }
    })

    useEffect(() => {
        setGreeting(greetings[greetingIndex])
    }, [greetingIndex])

    const renderGreeting = (greeting) => {

        if (greeting.includeName && username && username.length) {
            return <div>
                {greeting.value} <DisplayName displayName={username}/>!
            </div>
        }

        return <div>
            {greeting.value}!
        </div>
    }

    return <div className={[props.className]}>
        <div className={`${currentClass} Greeting ${hide ? "Hide": ""}`}>
            {renderGreeting(greeting)}
        </div>
    </div>
}

Greeting.propTypes = {
    displayName: PropTypes.string
}

export default Greeting