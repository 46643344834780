import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './LargeClock.scss'

const LargeClock = (props) => {

    const getTimeString = () => {
        let date = new Date()
        let dateString;
        if (props.showSeconds) {
            dateString = date.toLocaleTimeString()
        } else {
            dateString = date.toLocaleTimeString([], {
                hour: "2-digit", 
                minute: "2-digit",
            })
        }
    
        return dateString
    }

    const [time, setTime] = useState(getTimeString())

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(getTimeString())
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    })

    return <div className={[props.className]}>
            <h1 className="Clock">
                {time}
            </h1>
        </div>
}

LargeClock.propTypes = {
    showSeconds: PropTypes.bool.isRequired
}

export default LargeClock