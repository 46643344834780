const { INCREMENT_BACKGROUND_INDEX, UPDATE_BACKGROUND_TIMESTAMP } = require("../types/backgroundTypes")

const updateBackgroundIndex = (total) => {
    return {
        type: INCREMENT_BACKGROUND_INDEX,
        total
    }
}

const updateBackgroundTimestamp = () => {
    return {
        type: UPDATE_BACKGROUND_TIMESTAMP
    }
}

module.exports = {
    updateBackgroundIndex, 
    updateBackgroundTimestamp, 
}