const quotes = [
    {
        quote: "The similitude of someone who remembers his Lord and someone who does not is like that of the living and the dead.",
        source: "Bukhari"
    },
    {
        quote: "If you want to focus more on Allah in your prayers, focus more on Him outside your prayers.",
        source: "Yasmin Mogahed"
    },
    {
        quote: "The most beloved actions to Allah are those performed consistently, even if they are few.",
        source: "Sahih Bukhari"
    },
    {
        quote: "Indeed, I am near.",
        source: "Quran 2:186"
    },
    {
        quote: "Suffering is a gift. In it is hidden mercy.",
        source: "Rumi"
    },
    {
        quote: "And they planned and Allah also planned and Allah is the best of planners",
        source: "Quran 3:54"
    },
    {
        quote: "Verily, in the remembrance of Allah do hearts find rest.",
        source: "Quran 13:28"
    },
    {
        quote: "Worldly life is short, so turn to Allah before you return to Allah.",
        source: "Anonymous"
    },
    {
        quote: "Allah (God) does not burden a soul beyond that it can bear.",
        source: "Quran 2:286"
    },
    {
        quote: "And whoever turns away from My remembrance – indeed, he will have a depressed life.",
        source: "Quran 20:124"
    },
    {
        quote: "And it is He who created the night and day, and the sun and moon.",
        source: "Quran 21:33"
    },
    {
        quote: "For indeed, with hardship [will be] ease.",
        source: "Quran 94:5"
    },
    {
        quote: "The best of you are those who are best to their wives.",
        source: "At-Tirmidhi, Sahih"
    },
    {
        quote: "Speak only when your words are more beautiful than the silence.",
        source: "Anonymous"
    },
    {
        quote: "Allah is with the doers of good.",
        source: "Quran 29:69"
    },
    {
        quote: "Call upon Me, I will respond to you.",
        source: "Quran 40:60"
    },
    {
        quote: "And whoever puts all his trust in Allah, then He will suffice him.",
        source: "Quran 65:3"
    },
]

export default quotes