import './PrayerTimetable.scss'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useState } from 'react'
import { getCurrentPrayerTime, getNextPrayerTime } from '../../../data/Util/PrayerTimes/PrayerTimesUtil'

const PrayerTimetable = (props) => {
    const [currentPrayer, setCurrentPrayer] = useState()
    const [nextPrayer, setNextPrayer] = useState()

    useEffect(() => {
        let currentTime = new Date()
        
        let current = getCurrentPrayerTime(props.timetable, currentTime)
        setCurrentPrayer(current)
        
        let next = getNextPrayerTime(props.timetable, currentTime)
        setNextPrayer(next)
    }, [props.timetable])

    const renderTimetable = () => {
        const tableRows = Object.keys(props.timetable).map((rowName, index) => {
            let additionalClasses = " "
            if (rowName === currentPrayer) {
                additionalClasses += "Current-Prayer-Row "
            }
            if (rowName === nextPrayer) {
                additionalClasses += "Next-Prayer-Row"
            }
            return <div className={"Table-Row" + additionalClasses} key={index}>
                <span className="Event-Name">
                    {rowName}
                </span>
                <span className="Event-Time">
                    {props.timetable[rowName]}
                </span>
            </div>
        })
        return <div className="Prayer-Timetable">
            {tableRows}
        </div>
    }

    return renderTimetable()
}

PrayerTimetable.propTypes = {
    timetable: PropTypes.object.isRequired
}

export default PrayerTimetable