import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import SEARCH_ENDPOINTS from '../../../data/SearchEngine/SearchEngines'
import { updateSearchEngine, updateUsername } from '../../../redux/actions/userActions'
import Select from '../../atoms/Select/Select'
import ToggleButton from '../../atoms/ToggleButton/ToggleButton'
import TextInput from '../../atoms/TextInput/TextInput'
import LocationSearch from '../../molecules/LocationSearch/LocationSearch'
import './Settings.scss'
import { Button } from '../../atoms/Button/Button'
import { logAnalytics } from '../../../data/Analytics/analytics'

const Settings = (props) => {
    const name = useSelector(state => state.user.name)
    const location = useSelector(state => state.user.location)
    const searchEngine = useSelector(state => state.user.searchEngine)
    const dispatch = useDispatch()

    const renderSettings = () => {
        const cog = <ToggleButton 
            content={<span>Options</span>}
            show={props.showSettings} 
            toggle={props.toggleSettings}/>
        if (!props.showSettings) {
            return <div className="Settings">
                {cog}
            </div>
        }

        return <div className="Settings">
            <div className="Settings-Panel">
                <h2 className="Header">
                    Settings
                </h2>
                <hr className="Divider"/>
                <div className="Settings-Item">
                    <TextInput 
                        label="Display Name" 
                        value={name}
                        onChange={(newName) => {
                            dispatch(updateUsername(newName))
                            logAnalytics('update_display_name')
                        }}/>
                </div>
                <div className="Settings-Item">
                    <Select 
                        label="Search Engine" 
                        options={Object.keys(SEARCH_ENDPOINTS)}
                        value={searchEngine}
                        onChange={(newEngine) => {
                            dispatch(updateSearchEngine(newEngine))
                            logAnalytics('set_search_engine:' + newEngine)
                        }}/>
                </div>
                <div className="Settings-Item">
                    <LocationSearch location={location}/>
                </div>
                <Button 
                    className="Settings-Done"
                    onPress={() => props.toggleSettings(!props.showSettings)} text="Done"/>
            </div>
        </div>  
    }

    return renderSettings()
}

Settings.propTypes = {
    showSettings: PropTypes.bool.isRequired,
    toggleSettings: PropTypes.func.isRequired
}

export default Settings