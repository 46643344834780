import './PrayerTimetableHeader.scss'
import PropTypes from 'prop-types'
import { getHijriDateString } from '../../../data/Util/PrayerTimes/PrayerTimesUtil'

const TimetableHeader = (props) => {

    const renderHeader = () => {
        let gregDateString = new Date().toDateString()
        let hijriDateString = getHijriDateString(props.hijriDate)

        return <div className="Prayer-Times-Header">
            <h2 id="Header">
                Salah times
            </h2>
            <div>
                {gregDateString}
                <br />
                {hijriDateString}
            </div>
        </div>
    }

    return renderHeader()
}

TimetableHeader.propTypes = {
    hijriDate: PropTypes.object.isRequired
}

export default TimetableHeader