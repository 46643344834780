const greetings = [
    {
        value: "Assalamu Alaikum",
        includeName: true
    },
    {
        value: "Subhan Allah"
    },
    {
        value: "Alhamdulillah"
    },
    {
        value: "La Ilaha Illallah"
    },
    {
        value: "Allahu Akbar"
    },
    {
        value: "SubhanAllah Wa Bihamdihi"
    },
    {
        value: "Subhanallahil Azeem"
    }
]

export default greetings