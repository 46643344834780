import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB8ZE-6FCLz2dhjhO4HUbv9L_xZkK3w1f8",
  authDomain: "tabs-for-ajr.firebaseapp.com",
  projectId: "tabs-for-ajr",
  storageBucket: "tabs-for-ajr.appspot.com",
  messagingSenderId: "640425613881",
  appId: "1:640425613881:web:a48dfe94571437d9310be8",
  measurementId: "G-1CG9NV4MEY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const logAnalytics = (tag) => {
    logEvent(analytics, tag)
}

export {
    analytics, logAnalytics
}