import { useEffect } from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { updateUserLocation } from "../../../redux/actions/userActions"
import { getLocationData } from '../../../data/Location.js/Location'
import PropTypes from 'prop-types'
import './LocationSearch.scss'

import TextInput from "../../atoms/TextInput/TextInput"
import { getTodaysTimes } from "../../../data/PrayerTimes/PrayerTimes"
import { updatePrayerTimestamp, updateTodaysTimes } from "../../../redux/actions/prayerTimesActions"
import { logAnalytics } from "../../../data/Analytics/analytics"

const LocationSearch = (props) => {
    const location = props.location
    const [inputLocation, setInputLocation] = useState(location || '')
    const [searchTimeout, setSearchTimeout] = useState()
    const [loading, setLoading] = useState(false)
    const [showResults, setShowResults] = useState(false)
    const [searchResults, setResults] = useState()

    const dispatch = useDispatch()

    useEffect(() => {
        if (!inputLocation || !inputLocation.length) {
            setShowResults(true)
        }
    }, [inputLocation])

    const searchForLocation = (inputLocation) => {
        if (!inputLocation || inputLocation.length === 0) {
            dispatch(updateUserLocation(""))
        }
        if (searchTimeout) {
            clearTimeout(searchTimeout)
            setLoading(false)
        }
        let timeoutId = setTimeout(() => {
            getLocationData(inputLocation)
            .then(results => {
                setResults(results)
                setLoading(false)
            })
        }, 500)
        setLoading(true)
        setSearchTimeout(timeoutId)
        setInputLocation(inputLocation)
    }

    const renderLoading = () => {
        return <div className="Results">
            <div className="Result-Item">Loading...</div>
        </div>
    }

    const renderResults = () => {

        if (!showResults) {
            return <></>
        }

        let emptyResult = <div className="Results">
            <div className="Result-Item">Enter your nearest city (e.g. London)</div>
        </div>


        if (!searchResults) {
            return emptyResult
        }

        if (!searchResults.length) {
            return emptyResult
        }

        let locations = searchResults.map((location, index) => {
            return <div 
                onClick={() => {
                    dispatch(updateUserLocation(location))
                    setInputLocation(location)
                    logAnalytics('set_location:' + location)
                    getTodaysTimes(location)
                    .then(times => {
                        dispatch(updatePrayerTimestamp())
                        dispatch(updateTodaysTimes(times))
                    })
                }}
                className="Result-Item" 
                key={index}>{location}</div>
        })

        return <div className="Results">
            {locations}
        </div>
    }

    return <div>
        <TextInput 
            label="City" 
            value={inputLocation}
            onChange={(newLocation) => searchForLocation(newLocation)}/>
        <div>
            {loading ? renderLoading() : renderResults()}
        </div>
    </div>
}

LocationSearch.propTypes = {
    location: PropTypes.string
}

export default LocationSearch