import PropTypes from 'prop-types'

const DisplayName = (props) => {
    return <span>
        {props.displayName}
    </span>
}

DisplayName.propTypes = {
    displayName: PropTypes.string
}

export default DisplayName