import axios from "axios"

const getEndpoint = (location, school = -1) => {
    return `https://api.aladhan.com/v1/timingsByAddress?address=${location}&school=${school}`
}

const getTodaysTimes = (location, school = -1) => {
    const endpoint = getEndpoint(location, school) 
    return axios.get(endpoint)
        .then(response => {
            console.log("Provided location: " + location)
            console.log("Retreieved times fro location: " + response.data.data.meta.timezone)
            return response.data.data
        })
        .catch(err => {
            console.error(err)
            return err
        })
}

export {
    getTodaysTimes
}