const { INCREMENT_QUOTE_INDEX } = require("../types/quoteTypes")

const updateQuoteIndex = (total) => {
    return {
        type: INCREMENT_QUOTE_INDEX,
        total
    }
}

module.exports = {
    updateQuoteIndex
}