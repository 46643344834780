import './Button.scss'
import PropTypes from 'prop-types'

const Button = (props) => {
    return <div onClick={props.onPress}
        className={"Button-Group Button-Group-Item " + (props.className ? props.className : "")}>
        {props.text}
    </div>
}

Button.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired
}

export {
    Button
}