import { combineReducers } from 'redux'
import { backgroundReducer } from './backgroundReducer'
import { prayerTimesReducer } from './prayerTimesReducer'
import { quoteReducer } from './quoteReducer'
import { userReducer } from './userReducer'

const rootReducer = combineReducers({
    background: backgroundReducer,
    quote: quoteReducer,
    user: userReducer,
    prayerTimes: prayerTimesReducer
})

export default rootReducer