/**
 * 
 * @param {*} d1 This must be the unix time stamp
 * @param {*} d2 The date object from new Date()
 * @returns 
 */
const numDaysBetween = (d1, d2) => {
    d1 = d1 / 1000
    var diff = Math.abs(d1 - (d2.getTime() / 1000));
    return diff / (60 * 60 * 24);
};

export {
    numDaysBetween
}