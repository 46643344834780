
const { default: INITIAL_STATE } = require("../initialState");
const { 
    UPDATE_USER_LOCATION, 
    UPDATE_USER_NAME,
    UPDATE_SEARCH_ENGINE
} = require("../types/userTypes");

const userReducer = (state = INITIAL_STATE.user, action) => {
    let newState = {}
    switch (action.type) {
        case UPDATE_USER_NAME:
            Object.assign(newState, state)
            newState["name"] = action.name
            return newState
        case UPDATE_USER_LOCATION:
            Object.assign(newState, state)
            newState["location"] = action.location
            return newState
        case UPDATE_SEARCH_ENGINE:
            Object.assign(newState, state)
            newState["searchEngine"] = action.searchEngine
            return newState
        default:
            return state
    }
}

module.exports = {
    userReducer
}