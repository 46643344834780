import './About.scss'
import { Fragment, useEffect } from 'react';
import ScrollDownText from '../../atoms/ScrollDownText/ScrollDownText';
import Copyright from '../../atoms/Copyright/Copyright';
import { logAnalytics } from '../../../data/Analytics/analytics';
import Helmet from 'react-helmet';

const About = (props) => {

    useEffect(() => {
        logAnalytics('about_page_view')
    }, []) 

    const renderScrollPoints = (elements) => {
        return elements.map((element, index) => {
            const isLast = index === elements.length - 1
            const isFirst = index === 0;
            return <Fragment key={index}> 
                <div className="Image-Background"/>
                <div className="Content">
                    <div style={{marginTop: !isFirst ? "50vh" : 0, color:"white"}}>{element}</div>
                </div>

                {!isLast ? <ScrollDownText/> : <></>}
            </Fragment>
        })
    }

    const points = [
        <>
            <h2>
                Assalamu Alaikum!
            </h2>
        </>,
        <h3>So, what's this about?</h3>,
        <h3>It's simple! Each time you open a tab, we remind you to remember Allah</h3>,
        <h3>Tabs = Ajr, Ajr From Tabs</h3>,
        <h3>p.s. share for more Ajr</h3>,
        <>
            <h3>
                Get started by installing our <a onClick={() => logAnalytics('chrome_edge_ext')} style={{color: "white"}} target="_blank" href="https://chrome.google.com/webstore/detail/ajr-from-tabs/npnpahfdjjhpenenfnomniegjfdgkgfi?hl=en-GB&authuser=0">extension</a> on Chrome or Edge     
            </h3>
            <h3>
                And on <a onClick={() => logAnalytics('firefox_ext')} style={{color: "white"}} target="_blank" href="https://addons.mozilla.org/en-GB/firefox/addon/ajr-from-tabs/">firefox</a>
            </h3>
            <h3>
                Safari users, can change their homepage to ajrfrom.com/tabs, by following these <a onClick={() => logAnalytics('safari_steps')} style={{color: "white"}} target="_blank"  href="https://support.apple.com/en-gb/guide/safari/ibrw1020/mac">steps</a>.
            </h3>
        </>,
        <h3>
            <a style={{color: "white"}} target="_blank" href="https://ajrfrom.com/tabs">Get started!</a>
        </h3>,
    ]

    return <div>
        <div className="About">
            <Helmet>
                <title>About Ajr | From Tabs </title>
            </Helmet>
            <h1 className="Header">Ajr From Tabs</h1>
            {renderScrollPoints(points)}
            <Copyright/>
        </div>

    </div>
}

export default About