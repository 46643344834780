import './SearchInput.scss'
import PropTypes from 'prop-types'
import SearchRecommendations from '../../molecules/SearchRecommendations/SearchRecommendations'
import { useEffect } from 'react'
import { logAnalytics } from '../../../data/Analytics/analytics'

const SearchInput = (props) => {

    const addBlurToElement = (element) => {
        const classList = element.classList

        if (classList.contains("Remove-Blur")) {
            classList.remove("Remove-Blur")
        }

        if (!classList.contains("Add-Blur")) {
            classList.add("Add-Blur")
        }
        
    }

    const removeBlurFromElement = (element) => {
        const classList = element.classList

        if (classList.contains("Add-Blur")) {
            classList.remove("Add-Blur")
        }

        if (!classList.contains("Remove-Blur")) {
            classList.add("Remove-Blur")
        }
        
    }

    useEffect(() => {
        addBlurToElement(document.getElementById("Background-Image"))
    })

    return <div>
            <input
                type="text" 
                value={props.searchTerms}
                onChange={(e) => props.setTerms(e.target.value)}
                name="searchInput"
                placeholder="Search the web..."
                className="Search-Input" 
                id="searchInput"
                autoComplete={"off"}
                onKeyPress={(e) => {
                    if (e.key ==="Enter") {
                        logAnalytics('search_with_enter')
                        props.search()
                    }
                }}
                onBlur={() => {
                    removeBlurFromElement(document.getElementById("Background-Image"))
                }}
                onFocus={() => {
                    addBlurToElement(document.getElementById("Background-Image"))
                }}
                autoFocus/>
                <SearchRecommendations 
                    setTerms={props.setTerms}
                    search={props.search} 
                    searchTerm={props.searchTerms}/>
        </div>
}

SearchInput.propTypes = {
    searchTerms: PropTypes.string,
    setTerms: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired
}

export default SearchInput