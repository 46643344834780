import './ScrollDownText.scss';

const ScrollDownText = ({label}) => {
    return (
        <div style={{textAlign: "center",color:"white", }}
        >
            <div>
                {label ? label : ""}
            </div>
            <div>
                <div className="scroll"> &#8963; </div>
            </div>
        </div>
    )
}

export default ScrollDownText