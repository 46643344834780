const { UPDATE_PRAYER_DATESTAMP, UPDATE_TODAYS_TIMES } = require("../types/prayerTimeTypes")

const updateTodaysTimes = (timetable) => {
    return {
        type: UPDATE_TODAYS_TIMES,
        timetable
    }
}

const updatePrayerTimestamp = () => {
    return {
        type: UPDATE_PRAYER_DATESTAMP
    }
}

module.exports = {
    updateTodaysTimes, 
    updatePrayerTimestamp, 
}