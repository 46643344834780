const INITIAL_STATE = {
    background: {
        index: -1,
        lastUpdated: -1
    },
    quote: {
        index: -1
    },
    user: {
        name: "",
        location: "",
        searchEngine: ""
    },
    prayerTimes: {
        timetable: null,
        lastUpdated: -1
    }
}

export default INITIAL_STATE