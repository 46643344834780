/**
 * 
 * @param {Object} timetable Provide the current timetable
 * @param {Date} currentDate Provide a new object using new Date()
 */
const getNextPrayerTime = (timetable, currentDate) => {
    const prayers = ['Fajr', 'Dhuhr', 'Asr', 'Maghrib', 'Isha']
    const currentTime = currentDate.toLocaleTimeString([], {
        hour12: false, hour: "2-digit", minute: "2-digit"
    })

    if (currentTime >= timetable['Isha']) {
        return 'Fajr'
    }

    for (const prayer of prayers) {
        if (timetable[prayer] >= currentTime) return prayer
    }

}

/**
 * Get the current prayer
 * @param {Object} timetable Provide the current timetable
 * @param {Date} currentDate Provide a new object using new Date()
 */
 const getCurrentPrayerTime = (timetable, currentDate) => {
    const prayers = ['Fajr', 'Dhuhr', 'Asr', 'Maghrib', 'Isha']
    const currentTime = currentDate.toLocaleTimeString([], {
        hour12: false, hour: "2-digit", minute: "2-digit"
    })

    if (currentTime >= timetable['Isha'] || currentTime <= timetable['Fajr']) {
        return 'Isha'
    }

    for (let i = 0; i < prayers.length; i++) {
        let currentPrayer = timetable[prayers[i]]
        let nextPrayer = timetable[prayers[i + 1]]

        if (currentTime >= currentPrayer && currentTime <= nextPrayer) {
            return prayers[i]
        }
    }

}
const getHijriDateString = (hijriDateObject) => {
    const dayNo = hijriDateObject.day
    const month = hijriDateObject.month.en
    const year = hijriDateObject.year
    return `${dayNo} ${month} ${year}`
}

export {
    getNextPrayerTime,
    getCurrentPrayerTime,
    getHijriDateString,
}