import PropTypes from 'prop-types'
import './TextInput.scss'

const TextInput = (props) => {
    return <div className="TextInput">
        <label className="Label" htmlFor="text">
            {props.label}
        </label>
        <input 
            className="Text"
            type="text" 
            onChange={(e) => props.onChange(e.target.value)} 
            value={props.value}/>
    </div>
}

TextInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string
}

export default TextInput