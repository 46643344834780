import './SearchButton.scss'
import PropTypes from 'prop-types'
import { logAnalytics } from '../../../data/Analytics/analytics'

const SearchButton = (props) => {
    return <div>
        <button 
            onClick={() => {
                logAnalytics('search')
                props.search()
            }}
            className="Search-Button">Go!</button>
    </div>
}

SearchButton.propTypes = {
    search: PropTypes.func.isRequired
}

export default SearchButton