import './SearchRecommendations.scss'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useEffect } from 'react'
import { getAutocompleteRecs } from '../../../data/SearchEngine/Autocomplete'

const SearchRecommendations = (props) => {
    const [recs, setRecs] = useState([])
    const [autocompleteTimeout, setTimeoutId] = useState()
    const [clickedSearch, setClicked] = useState(false)

    useEffect(() => {
        if (!props.searchTerm && props.searchTerm.length <= 0) {
            return
        }
        if (autocompleteTimeout) {
            clearTimeout(autocompleteTimeout)
        }
        
        let timeoutId = setTimeout(() => {
            getAutocompleteRecs(props.searchTerm)
            .then(response => {
                setRecs(response.splice(0,6))
            })
        }, 100)
        setTimeoutId(timeoutId)
    }, [props.searchTerm])

    useEffect(() => {
        if (props.searchTerm && props.searchTerm.length && clickedSearch) {
            props.search()
        }
    }, [props.searchTerm, clickedSearch])

    const renderRecommendations = () => {
        if (!props.searchTerm || props.searchTerm.length <= 0 || recs.length <= 0) {
            return <></>
        }

        let recommendations = recs.map((rec, index) => {
            return <div
                onClick={() => {
                    props.setTerms(rec)
                    setClicked(true)
                }} 
                key={index}
                className="Recommendation">
                {rec}
            </div>
        })

        return <div className="Recommendations Recommendations-Focused">
            {recommendations}
        </div>
    }

    return renderRecommendations()
}

SearchRecommendations.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    search: PropTypes.func.isRequired,
    setTerms: PropTypes.func.isRequired
}

export default SearchRecommendations