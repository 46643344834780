import './PrayerTimes.scss'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button } from '../../atoms/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getTodaysTimes } from '../../../data/PrayerTimes/PrayerTimes'
import { numDaysBetween } from '../../../data/Util/Date/DateUtil'
import { updatePrayerTimestamp, updateTodaysTimes } from '../../../redux/actions/prayerTimesActions'
import PrayerTimetable from '../../molecules/PrayerTimetable/PrayerTimetable'
import { getHijriDateString, getNextPrayerTime, getCurrentPrayerTime, isWithinXMinutes } from '../../../data/Util/PrayerTimes/PrayerTimesUtil'
import TimetableHeader from '../../molecules/PrayerTimetableHeader/PrayerTimetableHeader'
import { Fragment } from 'react'
import moment from 'moment'

const PrayerTimes = (props) => {
    const prayerInfo = useSelector(state => state.prayerTimes.timetable)
    const prayerInfoLastUpdated = useSelector(state => state.prayerTimes.lastUpdated)
    const userCity = useSelector(state => state.user.location)

    const dispatch = useDispatch()

    const getPrayerTimes = () => {
        if (!userCity) return;
        return getTodaysTimes(userCity)
        .then(times => {
            dispatch(updatePrayerTimestamp())
            dispatch(updateTodaysTimes(times))
        })
    }

    useEffect(() => {
        if (!prayerInfo) {
            getPrayerTimes()
            return;
        }

        let now = new Date().getDate()
        let difference = Math.abs(prayerInfoLastUpdated - now)
        if (difference >= 1) {
            getPrayerTimes()
        }

    }, [prayerInfo, prayerInfoLastUpdated])

    const renderHeader = () => {
        return <TimetableHeader hijriDate={prayerInfo.date.hijri}/>
    }

    const renderPrayerHeadline = () => {
        let currentTime = new Date()
        const nextPrayer = getNextPrayerTime(prayerInfo.timings, currentTime)

        let difference = moment.utc(currentTime.toLocaleTimeString(), 'HH:mm:ss').diff(moment.utc(prayerInfo.timings[nextPrayer], 'HH:mm'), 'minutes')

        if (Math.abs(difference) < 10) {
            return <div className="Prayer-Headline">
                {nextPrayer} starts at {prayerInfo.timings[nextPrayer]}, pray on time!
            </div>
        }
        
        return <div className="Prayer-Headline">
            Coming up, {nextPrayer} at {prayerInfo.timings[nextPrayer]}
        </div>
    }

    const renderPrayerTimes = () => {
        const wrapper = (element) => {
            return <div className="Prayer-Times">{element}</div>
        }

        if (!userCity || !prayerInfo) {
            return wrapper(<div className="Set-Location" onClick={() => props.toggleSettings(true)}>
                    Set location to load prayer times
                </div>)
        }


        if (!props.showPrayerTimes) {
            return wrapper(<div 
                onClick={() => props.togglePrayerTimes(!props.showPrayerTimes)} 
                className="Next-Prayer Prayer-Times-Toggle">
                    {renderPrayerHeadline()}
                </div>)
        }

        return wrapper(<div className="Prayer-Times Prayer-Panel">
            {renderHeader()}
            <PrayerTimetable timetable={prayerInfo.timings}/>
            <br />
            <p>
                Showing prayer times for: <br /> <br /> {userCity}. 
            </p>
            <p onClick={() => props.toggleSettings(true)} style={{cursor: "pointer"}}>
                Click here to change your location
            </p>
            <Button onPress={() => props.togglePrayerTimes(!props.showPrayerTimes)} text="Close" />
        </div>)

    }

    return renderPrayerTimes()
}

PrayerTimes.propTypes = {
    showPrayerTimes: PropTypes.bool.isRequired,
    togglePrayerTimes: PropTypes.func.isRequired,
    toggleSettings: PropTypes.func.isRequired
}

export {
    PrayerTimes
}