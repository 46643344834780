import axios from 'axios'


const LIMIT = 5
const getLocationData = (searchTerm) => {
    if (!searchTerm) return Promise.resolve()
    
    const LOCATION_ENDPOINT = `https://api.teleport.org/api/cities/?search=${searchTerm}&limit=${LIMIT}`
    return axios.get(LOCATION_ENDPOINT)
        .then(response => {
            let top5Results = response
                .data._embedded["city:search-results"]
                .map(result => result.matching_full_name)
                .slice(0,5)

            return top5Results
        })
}

export {
    getLocationData
}