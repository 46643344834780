import './Quote.scss'
import quotes from '../../../data/Quotes/Quotes'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuoteIndex } from '../../../redux/actions/quoteActions'

const Quote = (props) => {
    const dispatch = useDispatch()
    const currentIndex = useSelector(state => state.quote.index)

    useEffect(() => {
        dispatch(updateQuoteIndex(quotes.length))
    }, [])

    const renderQuote = () => {
        if (!quotes || currentIndex < 0) {
            return <></>
        }
        return <div>
            {quotes[currentIndex].quote}
            <br />- {quotes[currentIndex].source}
        </div>
    }

    return <div className="Quote">
        {renderQuote()}
    </div>
}

export default Quote