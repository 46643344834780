const { UPDATE_USER_LOCATION, UPDATE_USER_NAME, UPDATE_SEARCH_ENGINE } = require("../types/userTypes")

const updateUsername = (name) => {
    return {
        type: UPDATE_USER_NAME,
        name
    }
}

const updateUserLocation = (location) => {
    return {
        type: UPDATE_USER_LOCATION,
        location
    }
}

const updateSearchEngine = (searchEngine) => {
    return {
        type: UPDATE_SEARCH_ENGINE,
        searchEngine
    }
}

module.exports = {
    updateUsername, 
    updateUserLocation,
    updateSearchEngine 
}