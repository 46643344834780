
const { default: INITIAL_STATE } = require("../initialState");
const { 
    INCREMENT_QUOTE_INDEX, 
} = require("../types/quoteTypes");

const quoteReducer = (state = INITIAL_STATE.quote, action) => {
    let newState = {}
    switch (action.type) {
        case INCREMENT_QUOTE_INDEX:
            let newIndex = (state.index + 1) % action.total
            Object.assign(newState, state)
            newState["index"] = newIndex
            return newState
        default:
            return state
    }
}

module.exports = {
    quoteReducer
}