
const { default: INITIAL_STATE } = require("../initialState");
const { 
    UPDATE_TODAYS_TIMES, 
    UPDATE_PRAYER_DATESTAMP,
} = require("../types/prayerTimeTypes");

const prayerTimesReducer = (state = INITIAL_STATE.prayerTimes, action) => {
    let newState = {}
    switch (action.type) {
        case UPDATE_TODAYS_TIMES:
            let newTimetable = action.timetable
            Object.assign(newState, state)
            newState["timetable"] = newTimetable
            return newState
        case UPDATE_PRAYER_DATESTAMP:
            let newDate = new Date().getDate()
            Object.assign(newState, state)
            newState["lastUpdated"] = newDate
            return newState
        default:
            return state
    }
}

module.exports = {
    prayerTimesReducer
}