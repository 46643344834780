import './App.scss'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import {store, persistor} from './redux/store'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import NewTab from './components/templates/NewTab/NewTab';
import About from './components/templates/About/About';
import GetStarted from './components/templates/GetStarted/GetStarted';

function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Switch>
              <Route path="/tabs">
                <NewTab/>
              </Route>
              <Route path="/about">
                <About/>
              </Route>
              <Route path="/getstarted">
                <GetStarted/>
              </Route>
              <Route>
                <NewTab/>
              </Route>
            </Switch>
          </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
