import { useEffect, useState } from 'react'
import './BackgroundImage.scss'
import backgroundImageConfig from '../../../background-images/config'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { updateBackgroundIndex, updateBackgroundTimestamp } from '../../../redux/actions/backgroundActions';
import imageConfigs from '../../../background-images/config';
import { numDaysBetween } from '../../../data/Util/Date/DateUtil';

const BackgroundImage = (props) => {
    const backgroundIndex = useSelector(state => state.background.index)
    const backgroundLastUpdated = useSelector(state => state.background.lastUpdated)
    const [imageClass, setImageClass] = useState("Hide")

    const dispatch = useDispatch()

    const [imageConfig, setImageConfig] = useState(imageConfigs[0])
    const [credits, setCredits] = useState({})

    useEffect(() => {
        let now = new Date()
        let difference = numDaysBetween(backgroundLastUpdated, now)
        if (difference >= 1) {
            dispatch(updateBackgroundIndex(Object.keys(imageConfigs).length))
            dispatch(updateBackgroundTimestamp())
        }

    }, [backgroundLastUpdated])

    useEffect(() => {
        setImageConfig(backgroundImageConfig[backgroundIndex])
    }, [backgroundIndex])

    useEffect(() => {
        if (imageConfig) {
            let background = window.document.getElementsByClassName('Background')[0];
            if (imageConfig?.imageTopOffset) {
                background.style.top = (imageConfig.imageTopOffset*-1) + "vh"
            } else {
                background.style.top = 0;
            }
            let appElements = window.document.getElementsByClassName('App')
            if (imageConfig.whiteText) {
                for (let i = 0; i < appElements.length; i++) {
                    let currElem = appElements[i]
                    currElem.style.color = "white"
                }
            } else {
                for (let i = 0; i < appElements.length; i++) {
                    let currElem = appElements[i]
                    currElem.style.color = "black"
                }
            }
        }
    }, [imageConfig])

    useEffect(() => {
        if (imageConfig && imageConfig.credit) {
            setCredits(imageConfig.credit)
        }
    }, [imageConfig])

    const renderCredits = () => {
        if (credits) {
            return <div id="Credit" className="Credit">
                Photo by {credits.name} on <a href={credits.url} target="_blank" rel="noreferrer">
                    {credits.site}
                </a>
            </div>
        }
    }
 
    const renderBackground = () => {

        return <div>
            <img 
                id="Background-Image"
                className={props.className + " Image " + imageClass} 
                src={`/img/${backgroundIndex}.jpeg`} 
                alt="Extension background"
                onLoad={() => {
                    setImageClass("Fade-In")
                }}/>
            {renderCredits()}
        </div>
    }

    return renderBackground()
}

BackgroundImage.propTypes = {
    backgroundImageIndex: PropTypes.number.isRequired,
}

export default BackgroundImage