import './GetStarted.scss'

const GetStarted = (props) => {
    return <div className="Content">
        <section className="Section">
            <h1>
                Let's get you started!
            </h1>
            <div className="Headers">
                <h2>
                    Chrome, Edge or Firefox
                </h2>
            </div>

            <div className="Headers">
                <h2>
                    Safari and other browsers
                </h2>
            </div>
        </section>
    </div>
}

export default GetStarted